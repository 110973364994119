<template>
	<div class="company-profile">
		<v-row no-gutters dense class="d-flex" style="height:inherit">
			<v-col cols="12" class="col-lg-6 pt-8 my-pr">
				<app-transaction></app-transaction>
			</v-col>
			<v-col cols="12" class="col-lg-6 pt-8 my-pl">
				<app-bank-account></app-bank-account>
			</v-col>
		</v-row>
	</div>
</template>

<script>
const Transaction = () => import("./Transaction");
const BankAccount = () => import("./BankAccount");
export default {
	components: {
		AppTransaction: Transaction,
		AppBankAccount: BankAccount
	}
};
</script>

<style lang="scss" scoped>
.company-profile {
	height: 100% !important;
}
</style>